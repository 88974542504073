<template>
	<el-menu
		class="v-meu-new"
		mode = "horizontal"
		background-color="#3f51b5"
		text-color="#fff"
		active-text-color="#ffd04b"
		:default-active="$route.path"
		:router="true">
			<menu-item :menus="menuList"></menu-item>
	</el-menu>
</template>

<script type="text/javascript">
	import MenuItem from "./MenuItem.vue"
	export default {
		name : "v-menu-list",
		components : {
			MenuItem
		},
		props : {
			//传进来的菜单数据
			menus : {
				type : Array,
				default : ()=>([])
			}
		},
		data() {
			return {
				isOverride: false,
			}
		},
		mounted() {
			this.checkOverride();
		},
		methods: {
			checkOverride() {
				if(this.menus.length < 3) {
					return;
				}
				this.$nextTick(() => {
					if(this.$el.offsetHeight > 50) {
						this.isOverride = true;
					}
				});
			}
		},
		computed: {
			menuList() {
				if(this.isOverride) {
					return [{childs: this.menus, desc: 'Menu', id: -1, menuOrder: 0, name: 'menu'}];
				}
				return this.menus;
			}
		},
		watch: {
			menus() {
				this.checkOverride();
			}
		},
	};
</script>

<style lang="scss">
	.v-meu-new{
		&.el-menu--popup-bottom-start{
			margin-top : -5px !important;
		}
		&.el-menu--horizontal > .el-submenu .el-submenu__title , &.el-menu--horizontal > .el-menu-item{
			height : 48px !important;
			line-height : 48px !important;
		}
		&.el-menu.el-menu--horizontal{
			border-bottom : 0px !important;
		}
	}

	.el-menu--popup {
		max-height: calc(100vh - 20px);
		overflow-y: auto;
	}
	
</style>